import React, {Component} from 'react';
import {Mutation, withApollo} from "react-apollo";
import * as i18n from "i18next";
import {createTaskMutation, customerId} from "../../queries/Queries";
import LoadingImage from "../../images/loading-grey.svg";
import gql from "graphql-tag";
import Form from "react-jsonschema-form";
import customFormats from "../../schemas/customFormats";
import schema from "../../schemas/task.schema";
import uischema from "../../schemas/task.uischema";
import ReactDOM from "react-dom";
import ModalDialog from "../../components/ModalDialog";
// import EventLogger from "../../components/EventLogger";
// import ErrLogger from "../../components/ErrorLogger";
import ExpiringAlert from "../../components/ExpiringAlert";
import TasksAttachments from "../../components/TasksAttachments";
import CustomerSearch from "../../total/components/CustomerSearch";
import {Button, Col, Row} from "reactstrap";
import {withRouter} from "react-router-dom";
import {getSchemaCreteQuery, getType} from '../../utils/Helpers';

class CreateTaskView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            siteId: null,
            timestamp: Date.now(),
            schema: {}
        }

        this.onChange = this.onChange.bind(this);
        this.getCustomer = this.getCustomer.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    cancel() {
        this.setState({timestamp: Date.now()})
    }

    onChange(selected) {
        this.setState({
            loadingTask: true,
        });
        this.getCustomer(selected);
    }

    getCustomer(selected) {
        let accountNumber = selected.account.number;
        let siteId = selected.site.id;
        let accounts = [];
        accounts.push(String(selected.account.number));
        let meterPointIds = selected.meterPoints.map(o => o.id).join(',');
        let meterPoints = [""];
        let mp = meterPointIds.split(",");
        meterPoints = meterPoints.concat(mp);

        let where = "accounts.number==" + accountNumber;
        this.props.client.query({
            query: customerId,
            "variables": {
                "pageSize": 1,
                "pageNumber": 0,
                "sort": {
                    "orders": [
                        {
                            "property": "name",
                            "direction": "asc"
                        }
                    ]
                },
                "where": where
            },
        }).then(({data}) => {
            this.setState({
                customer: data.Customer.content[0].id,
                siteId: siteId,
                propertyName: selected.name,
                account: selected.account.number,
                meterPoints: meterPoints,
                meterPointsData: selected && selected.site && selected.site.meterPoints && Array.isArray(selected.site.meterPoints) && selected.site.meterPoints.length ? selected.site.meterPoints : [],
                schema: getSchemaCreteQuery(schema, selected && selected.site && selected.site.meterPoints && Array.isArray(selected.site.meterPoints) && selected.site.meterPoints.length ? selected.site.meterPoints : []),
                accounts: accounts,
                loadingTask: false
            });
        });
    }

    render() {

        const refetchQuery = sessionStorage.listOfTasksQuery && JSON.parse(sessionStorage.listOfTasksQuery);
        if (refetchQuery) {
            refetchQuery.query = refetchQuery.query && gql`${refetchQuery.query}`;
        }
        const taskComponent = this;
        return (
        <div className="service-panel page-cnt">

            <Row className='subheader service-header'>
                <Col className='col-8'>
                    <h1>{i18n.t('tasks.addTaskForSite')}</h1>
                </Col>
                <Col className="col-4">
                    <div className="dropdown show">
                        <Button className="pull-right" color="primary"
                                onClick={this.props.history.goBack}><i
                            className="ion ion-ios-arrow-back"/> {i18n.t('search.go-back')}</Button>
                    </div>
                </Col>
            </Row>

            <div className="service-panel-search">
                <CustomerSearch onChange={this.onChange} activeAccounts={true}/>
            </div>

            <div style={{display: this.state.loadingTask ? 'block' : 'none'}} className="loading-img">
                <img src={LoadingImage}/>
            </div>

            <div style={{display: this.state.siteId ? 'block' : 'none'}} className="create-task-modal"
                 key={this.state.timestamp}>
                <div style={{display: this.state.loading ? 'block' : 'none'}} className="loading-img">
                    <img src={LoadingImage}/>
                </div>

                <div>
                    <div className="task-labels clearfix">
                        <span className="pull-left">{i18n.t('tasks.addTask')}</span>
                    </div>

                    <Mutation mutation={gql`${createTaskMutation}`} refetchQueries={refetchQuery && [refetchQuery]}>
                        {(createTask, {data}) => (
                            <Form showErrorList={false} customFormats={customFormats} schema={this.state.schema} noHtml5Validate
                                  transformErrors={errors => errors.map(err => {
                                      if (err.message.startsWith("should match format")) {
                                          const format = err.message.substr()
                                          switch (format.substr(20)) {
                                              case '"lettersOnly"' :
                                                  err.message = "should contain only letters";
                                                  break;
                                              case    '"phone"':
                                                  err.message = "should contain only numbers";
                                                  break;
                                              case    '"numbersOnly"':
                                                  err.message = "should contain only numbers";
                                                  break;
                                              case    '"lettersAndNumbers"':
                                                  err.message = "should contain only letters and numbers";
                                                  break;
                                          }
                                      }
                                      return err;
                                  })}
                                  uiSchema={uischema}
                                  onSubmit={({formData}) => {

                                      this.setState({loading: true});

                                      const variables = [
                                          {
                                              name: "type",
                                              type: "string",
                                              value: formData.type + ""
                                          },
                                          {
                                              name: "status",
                                              type: "string",
                                              value: formData.status
                                          },
                                          {
                                              name: "contact_name",
                                              type: "string",
                                              value: formData.contact_name,
                                          },
                                          {
                                              name: "contact_number",
                                              type: "string",
                                              value: formData.contact_number
                                          },
                                          {
                                              name: "contact_email",
                                              type: "string",
                                              value: formData.contact_email
                                          },
                                          {
                                              name: "mprn",
                                              type: "string",
                                              value: formData.mprn
                                          },
                                          {
                                              name: "estate",
                                              type: "string",
                                              value: formData.estate
                                          },
                                          {
                                              name: "account_code",
                                              type: "string",
                                              value: formData.account_code
                                          }
                                      ];


                                      formData.change_of_od && Object.keys(formData.change_of_od).length > 0 && variables.push({
                                          name: "change_of_od",
                                          type: "string",
                                          value: JSON.stringify(formData.change_of_od)
                                      });
                                      formData.outgoing_customer && Object.keys(formData.outgoing_customer).length > 0 && variables.push({
                                          name: "outgoing_customer",
                                          type: "string",
                                          value: JSON.stringify(formData.outgoing_customer)
                                      });
                                      formData.incomming_customer && Object.keys(formData.incomming_customer).length > 0 && variables.push({
                                          name: "incomming_customer",
                                          type: "string",
                                          value: JSON.stringify(formData.incomming_customer)
                                      });
                                      formData.coo_pricing && Object.keys(formData.coo_pricing).length > 0 && variables.push({
                                          name: "coo_pricing",
                                          type: "string",
                                          value: JSON.stringify(formData.coo_pricing)
                                      });

                                      taskComponent.attachments && variables.push({
                                          name: "attachments",
                                          type: "string",
                                          value: "true"
                                      });

                                      createTask({
                                          variables: {
                                              task: {
                                                  name: formData.name,
                                                  delegationState: "pending",
                                                  dueDate: formData.dueDate,
                                                  description: formData.description,
                                                  assignee: formData.assignee,
                                                  priority: formData.priority,
                                                  variables: variables
                                              },
                                              type: getType(this.state.meterPointsData)
                                          }
                                      }).then((response) => {
                                          const taskId = response.data.createTask || response.data.createRemoteTask;
                                          if (!taskId) {
                                              // ErrLogger(formData, "SBMT_QUIRY_ERR", "Task id not returned");
                                              this.setState({loading: false, timestamp: Date.now()});
                                              ReactDOM.render(<ExpiringAlert color="danger"
                                                                             message={i18n.t('tasks.errorOnCreate')}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                          } else {
                                              this.child.uploadAttachments({
                                                  id: taskId
                                                  //service: "activiti"
                                              });
                                              if (window.gtag) {
                                                let pagename = ''
                                                    if(document.location.href.split('/').length>7){
                                                        pagename = document.location.href.split('/')[5] +' '+ document.location.href.split('/')[7]
                                                    }else{
                                                        pagename = document.location.href.split('/').pop()
                                                    }

                                                    pagename = pagename.charAt(0).toUpperCase() + pagename.slice(1) + ' ' + "Enter Query"
                                                    const path = document.location.href + '/tab_' + 'enter_query';
                                                    gtag('event', 'page_view', {
                                                        page_title: pagename,
                                                        page_location: path,
                                                        page_path: path,
                                                    })
                                            }
                                              ReactDOM.render(<ModalDialog title={i18n.t('tasks.successfulCreateTitle')}
                                                                           text={i18n.t('tasks.successfulCreate')}
                                                                           opened={true}
                                                                           closeLabel={"Close"}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                              // EventLogger(formData, "SBMT_QUIRY_OK");
                                          }
                                          this.setState({loading: false, timestamp: Date.now()});
                                      }).catch(
                                          (error) => {
                                              // ErrLogger(formData, "SBMT_QUIRY_ERR", error);
                                              this.setState({loading: false, timestamp: Date.now()});
                                              ReactDOM.render(<ExpiringAlert color="danger"
                                                                             message={i18n.t('tasks.errorOnCreate')}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                          }
                                      );

                                  }}
                                  formData={{
                                      dueDate: "",
                                      customer: this.state.customer,
                                      contact_email: this.props.configuration.global.user.username,
                                      estate: this.state.propertyName ? this.state.propertyName : '',
                                      mprn: this.state.meterPoints ? this.state.meterPoints[0] : '',
                                      account_code: this.state.account
                                  }}
                                  formContext={{
                                      accounts: this.state.accounts || [],
                                      meterPoints: this.state.meterPoints || [],
                                      meterPointsData: this.state.meterPointsData || []
                                  }}>
                                <TasksAttachments ref={instance => {
                                    this.child = instance;
                                }} uploadImmediately={false} parent={this}/>
                                <div className="buttons">
                                    <button type="submit" className="btn btn-primary">Add Query</button>
                                    <button type="button" className="btn btn-secondary"
                                            onClick={() => this.cancel()}>Clear form
                                    </button>
                                </div>
                            </Form>)
                        }
                    </Mutation>
                </div>
            </div>

        </div>);
    }
}

export default withApollo(withRouter(CreateTaskView));