import gql from "graphql-tag";

const getInvoiceQuery = gql`
    query invoiceQuery($where: String!, $orderBy: String!) {
        invoice(startRow:0, offset: 100, where: $where, orderBy: $orderBy) {
            id
            documentNo
            invoiceDate
            grandTotalAmount
            invoiceTaxList{
                taxAmount
                taxableAmount

            }
            invoiceDiscountList{
                discount{
                    discount
                }
            }
            currency{
                symbol
                iSOCode
            }
            billPeriod {
                id
                creationDate
                endingDate
                startingDate
            }
            fINPaymentScheduleList{
                dueDate
            }
            isPaymentComplete
            outstandingAmount

            noteList{
                id
                note
                creationDate
                updated
                createdBy{
                    name
                }
                updatedBy{
                    name
                }
            }
            attachmentList{
                id
                name
                creationDate
                updated
                text
                createdBy{
                    name
                }
                updatedBy{
                    name
                }
            }
        }
    }

`;
const getBalance = gql`
    query getBalance($id: String!){
        getBalance(bp_id:$id){
            balance
            customer
            details{
                balance
                date
                number
                total
                id
                currency
                currencyISOCode
                type
            }
            provider
        }
    }

`;
const getContractId = gql`
query contractQuery {
    contract(startRow: 0, offset: 100, where: "", orderBy: "id") {
        id
    }
}`;


const getContractQuery = gql`
    query contractQuery($where: String!, $orderBy: String!) {
        contract(startRow: 0, offset: 100, where: $where, orderBy: $orderBy) {
            id
            documentnumber
            creationDate
            expirationdate
            signdate
            startDate
            tariff
            dyceContractDurationInMonths
            mDMContractStatus{
                name
            }
            businessPartner{
                id
                name
                bILLBillingAccountList{
                    paymentMethod{
                        name
                    }
                    billingAccountNumber
                    currentStatus{
                        status
                    }
                }
            }
            noteList{
                id
                note
                creationDate
                updated
                createdBy{
                    name
                }
                updatedBy{
                    name
                }
            }
            attachmentList{
                id
                name
                creationDate
                updated
                text
                createdBy{
                    name
                }
                updatedBy{
                    name
                }
            }
        }
    }

`;


const getBusinessPartnerShortQuery = gql`
    query businessPartner($where: String!, $orderBy: String!) {
        businessPartner(startRow: 0, offset: 100, where: $where, orderBy: $orderBy) {
            id
            isMdmIslegalentity
            mdmEik
            mdmPersonalno
            name
            businessPartnerCategory {
                id
                name
            }
            cRMBpartnerContactList {
                user {
                    email
                    phone
                    isMdmIsowner
                }
            }
            bILLBillingAccountList {
                billingAccountNumber
            }

            mDMBPEstateList {
                mDMEstate {
                    mDMMeterPointList{
                        name
                    }
                }
            }
        }
    }
`;

const getBusinessPartnerQuery = gql`
    query businessPartnerQuery($startRow: Int!, $offset: Int!, $where: String!, $orderBy: String!) {
        businessPartner(startRow: $startRow, offset: $offset, where: $where, orderBy: $orderBy) {
            id
            isActive
            isMdmIslegalentity
            mdmEik
            mdmPersonalno
            name
            mdmCompanyName
            mdmCompanyType
            mdmContragentno
            paymentMethod{
                name
            }
            currency{
                iSOCode
            }
            businessPartnerBankAccountList {
                id
                accountNo
                accountType
                iBAN
                bankName
                swiftCode
                isActive
                mdmPreferredPaymentDate
                tspayDdSetUpStatus
                userContact{
                    name
                    firstName
                    lastName
                }
                ukmAdditionalAccntHldr
                name
                mdmBankinfo{
                    name
                }
            }
            mdmContactAddress{
                locationAddress{
                    addressLine1
                }
            }
            mDMContractList(startRow: 0, offset: 100){
                id
                documentnumber
                signdate
                expirationdate
                offerDate
                mDMContractStatus{
                    name
                }
                billBillingPlanList{
                    amountWithTax
                }
                marketingProduct {
                    name
                    productBOMList {
                        bOMProduct {
                            name
                        }
                    }
                }

                noteList{
                    id
                    note
                    creationDate
                    updated
                    createdBy{
                        name
                    }
                    updatedBy{
                        name
                    }
                }
                attachmentList{
                    id
                    name
                    creationDate
                    updated
                    text
                    createdBy{
                        name
                    }
                    updatedBy{
                        name
                    }
                }
            }
            businessPartnerCategory {
                id
                name
            }
            cRMBpartnerContactList {
                user {
                    id
                    isMdmIsowner
                    firstName
                    lastName
                    birthday
                    email
                    phone
                    mdmTitle
                    mdmContactAddress{
                        locationAddress{
                            addressLine1
                        }
                    }
                }
            }
            mDMBPEstateList {
                id
                mDMEstate {
                    id
                    locationAddress {
                        addressLine1
                    }
                    mDMMeterPointList{
                        id
                        name
                        mDMMeterRegisterList{
                            id
                            meterReadingDigitsCount
                            mDMRegister{
                                product{
                                    id
                                    name
                                }
                            }
                            meter{
                                id
                                serialNumber
                                ammMeterReadingList{
                                    id
                                    readingdate
                                    readStatus
                                    validationStatus
                                    newreadingmetervt
                                    customerReadType
                                    product {
                                        id
                                        searchKey
                                        mdmPurpose
                                        uOM {
                                            id
                                            name
                                        }
                                    }
                                    mDMMeterMeasurement {
                                        id
                                        reportedamount
                                    }
                                }
                            }
                        }
                    }
                }
            }
            fINPaymentList(startRow:0, offset: 100) {
                id
                organization {
                    name
                }
                documentType {
                    name
                }
                documentNo
                paymentDate
                paymentMethod {
                    name
                }
                status
                amount
                isBillRefunddepositBtn
                billContract {
                    documentnumber
                }
                tspayName
                posted
                isAprmExecutepayment
                billEstate {
                    name
                    consumernumber
                }
                usedCredit
                generatedCredit
                description
                currency {
                    iSOCode
                    symbol
                }
                referenceNo
                isBillForcedreverse
                billLicensingTerritory {
                    name
                }
                mdmTerritory
                isBillFinSysExported
            }
            bILLInvoicesPenaltiesVList(startRow:0, offset: 100) {
                id
                organization {
                    name
                }
                totalPaid
                totalamount
                invoiceDate
                documentNo
                amount
                totalPaid
                invoice{
                    noteList{
                        id
                    }
                    attachmentList{
                        id
                    }
                }
                billingDocumentType {
                    documentName
                    documentType{
                        name
                    }
                }
                currency {
                    symbol
                    iSOCode
                }
                flowtype
                dueDate
                isLicensing
                isProform
                isPenalty
                isPaymentComplete
                estate {
                    name
                    consumernumber
                }

                isActive
                period {
                    id
                    creationDate
                    endingDate
                    startingDate
                }
                businessPartner {
                    id
                    name
                }
                collStatus
                noteList{
                    id
                    note
                    creationDate
                    updated
                    createdBy{
                        name
                    }
                    updatedBy{
                        name
                    }
                }
                attachmentList{
                    id
                    name
                    creationDate
                    updated
                    text
                    createdBy{
                        name
                    }
                    updatedBy{
                        name
                    }
                }
            }
            printDocumentList(startRow:0, offset: 100, where: "billDoctype.documentName = 'Customer Statement'", orderBy:"id"){
                creationDate
                json
                document
            }
            noteList{
                id
                note
                creationDate
                updated
                createdBy{
                    name
                }
                updatedBy{
                    name
                }
            }
            attachmentList{
                id
                name
                creationDate
                updated
                text
                createdBy{
                    name
                }
                updatedBy{
                    name
                }
            }
        }
    }
`;

const getUserQuery = gql`
    query GetUser($username:String){
        getUser(username:$username){
            username
            enabled
            locked
            firstName
            middleName
            lastName
            title
            jobTitle
            avatarUrl
            phoneNumbers{
                phoneNumberId
                phoneNumber
                type
                primaryNumber
            }
            organizations{
                organizationId
                name
                businessPartnerId
                avatarUrl
                ultimateParent{
                    organizationId
                    name
                    businessPartnerId
                }
            }
            userSystems{
                userSystemNameId
                system{
                    systemName
                }
                systemUser
            }
            roles{
                role {
                    key
                    name
                    permissions{
                        permission{
                            key
                            name
                        }
                    }
                }
            }
        }
    }
`;

const getRolesForUserQuery = gql`
    query GetRolesForUser($username:String){
        getRolesForUser(username:$username)
    }
`;

const createUserMutation = gql`
    mutation CreateUser($user:com_methodia_cockpit_core_domain_UserInput){
        createUser(user: $user)
    }
`;
const createUserMutation2 = gql`
    mutation RegisterUser($user:com_methodia_cockpit_core_domain_UserInput, $token:String){
        registerUser(user: $user, token: $token)
    }
`;

const updateUserMutation = gql`
    mutation UpdateUser($user:com_methodia_cockpit_core_domain_UserInput){
        updateUser(user:$user)
    }
`;


const deleteUserMutation = gql`
    mutation DeleteUser($username:String!){
        deleteUser(username:$username)
    }
`;


const unlockUserMutation = gql`
    mutation UnlockUser($username:String){
        unlockUser(username: $username)
    }
`;
const changePasswordMutation = gql`
    mutation ChangePassword($user:com_methodia_cockpit_core_domain_UserInput){
        changeUserPassword(user:$user)
    }
`;

const changeOwnPasswordMutation = gql`
    mutation ChangeOwnPassword($passwordHolder:com_methodia_cockpit_core_domain_PasswordHolderInput){
        changeOwnPassword(passwordHolder:$passwordHolder)
    }
`;

const createUserRoles = gql`
    mutation CreateUserRoles($username:String, $roles:[String]){
        createUserRoles(username: $username, roles:$roles)
    }
`;

const deleteUserRoles = gql`
    mutation DeleteUserRoles($username:String, $roles:[String]){
        deleteUserRoles(username: $username, roles:$roles)
    }
`;



const getUsers = gql`
    query GetUsers {
        getUsers{
            username
            enabled
            locked
            firstName
            jobTitle
            lastName
            title
            avatarUrl
            roles{
                role {
                    key
                    name
                    permissions{
                        permission{
                            key
                            name
                        }
                    }
                }
            }
            phoneNumbers{
                phoneNumber
                primaryNumber
            }
            organizations{
                businessPartnerId
                avatarUrl
                name
            }
        }
    }
`;

const getAllRoles = gql`
    query GetAllRoles {
        getAllRoles{
            key
            name
            permissions{
                permission{
                    key
                    name
                }
            }
        }
    }
`;
const getAllPermissions = gql`
    query GetAllPermissions {
        getAllPermissions{
            key
            name
        }
    }
`;


const createUpdateRolesMutation = gql`
    mutation CreateUpdateRoles($roles: [com_methodia_cockpit_core_domain_RoleInput]){
        createUpdateRoles(roles: $roles)
    }
`;

const createRolePermissionsMutation = gql`
    mutation CreateRolePermissions($rolePermissions: [com_methodia_cockpit_core_domain_RolePermissionInput]){
        createRolePermissions(rolePermissions: $rolePermissions)
    }
`;
const deleteRolePermissionsMutation = gql`
    mutation DeleteRolePermissions($rolePermissions: [com_methodia_cockpit_core_domain_RolePermissionInput]){
        deleteRolePermissions(rolePermissions: $rolePermissions)
    }
`;
const createNoteMutation = gql`
    mutation CreateNote($content: String!, $contextTableName: String!, $recordId: String!){
        createNote(content: $content, contextTableName: $contextTableName, recordId: $recordId)
    }
`;

const updateNoteMutation = gql`
    mutation UpdateNote($content: String!, $id: String!){
        updateNote(content: $content, id: $id)
    }
`;
const deleteNoteMutation = gql`
    mutation DeleteNote($id: String!){
        deleteNote(id: $id)
    }
`;

const uiConfigurationQuery = gql`
    query getUIConfiguration{
        getUIConfiguration{
            name
            uiMenus{
                uiMenuItemOrders{
                    order
                    defaultMenu
                    uiMenuItem{
                        name
                        defaultLabel
                        type
                        icon
                        url
                        badgeText
                        badgeVariant
                    }
                    children{
                        order
                        defaultMenu
                        uiMenuItem{
                            name
                            defaultLabel
                            type
                            icon
                            url
                            badgeText
                            badgeVariant
                        }
                        children{
                            order
                            defaultMenu
                            uiMenuItem{
                                name
                                defaultLabel
                                type
                                icon
                                url
                                badgeText
                                badgeVariant
                            }
                        }
                    }
                }

            }
            uiLayouts{
                id
                jsx
                name
            }
            uiQueries{
                id
                name
                query
                variables
            }
        }

        Message(spec: {where: "read==false"}, page: {pageSize: 10, pageNumber: 0}) {
            totalElements
            content {
                type
                account {
                    customer {
                        contracts {
                            product
                        }
                    }
                    properties {
                        status
                        id
                        site {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

const allUiConfigurationsQuery = gql`
    query getAllUIConfigurations{
        getAllUIConfigurations{
            id
            role
            uiConfiguration{
                id
                name
                uiMenus{
                    id
                    uiMenuItemOrders{
                        id
                        order
                        defaultMenu
                        uiMenuItem{
                            id
                            name
                            defaultLabel
                            type
                            icon
                            url
                            badgeText
                            badgeVariant
                        }
                        children{
                            id
                            order
                            defaultMenu
                            uiMenuItem{
                                name
                                defaultLabel
                                type
                                icon
                                url
                                badgeText
                                badgeVariant
                            }
                            children{
                                order
                                defaultMenu
                                uiMenuItem{
                                    name
                                    defaultLabel
                                    type
                                    icon
                                    url
                                    badgeText
                                    badgeVariant
                                }
                            }
                        }
                    }

                }
                uiLayouts{
                    id
                    jsx
                    name
                }
                uiQueries{
                    id
                    name
                    query
                    variables
                }
            }
        }
    }
`;

const allUiMenuItemsQuery = gql`
    query getAllUIMenuItems{
        getAllUIMenuItems{
            id
            name
            defaultLabel
            type
            icon
            url
            badgeText
            badgeVariant
        }
    }
`;

const organizationsQuery = gql`
    query organizations {
        getOrganizations{
            organizationId
            name
            businessPartnerId
            avatarUrl
            parent {
                name
                organizationId
            }
            ultimateParent {
                organizationId
            }
        }
    }
`;

const systemsQuery = gql`
    query systems {
        getSystems{
            systemName
            displayName
            url
        }
    }
`;
const updateSystemMutation = gql`
    mutation updateSystem($systemName: String, $url:String, $displayName: String){
        updateSystem(systemName: $systemName, url: $url, displayName: $displayName)
    }
`;

const createReadingMutation = gql`
    mutation saveReading($reading: ReadingInput){
        saveReading(reading: $reading) {
            id
        }
    } 
`;

const createReadingsMutation = gql`
    mutation saveReadings($readings: [ReadingInput]){
        saveReadings(readings: $readings) {
            id
        }
    }
`;

const getTaskVariableQuery = gql`
  query getTaskVariable($taskId: String!, $variableName: String!){
      getTaskVariable(taskId: $taskId, variableName: $variableName)
  }  
`;

const getTasksQuery = gql`
  query getTasks{
      Query(page: {pageNumber: 0, pageSize: 10, sort: {orders: [{property: "createTime", direction:"desc"}]}}, where: "((variables.status==Open))") {
          content {
              id
              name
              description
              assignee
              owner
              priority
              createTime
              variables
              attachmentCount
          }
          size
          totalPages
          first
          last
          number
          numberOfElements
          size
          totalElements
          totalPages
          pageable {
              pageNumber
              pageSize
              paged
              offset
          }
      }
  }  
`;
const createTaskMutation = gql`
    mutation createRemoteTask($task: com_methodia_cockpit_core_domain_TaskInput, $type: String){
        createRemoteTask(task: $task, type: $type)
    }
`;



const updateTaskMutation = gql`
    mutation updateTask($task: com_methodia_cockpit_core_domain_TaskInput, $service: String){
        updateTask(task: $task, service: $service)
    }
`;

const deleteTaskMutation = gql`
    mutation deleteTask($taskId: String!){
        deleteTask(taskId: $taskId)
    }
`;

const createTaskCommentMutation = gql`
    mutation createTaskComment($taskId: String!, $comment: com_methodia_cockpit_core_domain_TaskCommentInput, $service: String){
        createTaskComment(taskId: $taskId, comment: $comment, service: $service)
    }
`;

const getTaskCommentsQuery = gql`
    query getTaskComments($taskId: String!, $service: String){
        getTaskComments(taskId: $taskId, service: $service){
            message
            id
            author
            time
        }
    }
`;



const startProcessMutation = gql`
    mutation startProcess($processId:String!, $variables:[com_methodia_cockpit_core_domain_VariableInput]){
      startProcess(processId:$processId, variables: $variables)
    }
`;

const periodsQuery = gql`
    query periodsQuery {
        periods {
            id
            periodNo
            periodType
            name
            organization {
                id
            }
            year {
                fiscalYear
            }
        }
    }  
`;
const getAddresses = gql`
    query getAddresses($postcode: String!) {
        getAddresses(postcode: $postcode){
            block
            city
            county
            dependentCity
            dependentStreet
            doubleDependentCity
            houseNumber
            houseNumberAdd
            mpan
            postalCode
            street
            ldz
            meterData{
                mprn
                gas_meter_serial_number
            }
        }
    }
`;

const getLdz = gql`
    query getLdz($postcode: String!) {
        getLdz(postcode: $postcode)
    }
`;

const createUpdateQuote = gql`
    mutation createUpdateQuote($quote: com_methodia_salesportal_domain_QuoteInput){
        createUpdateQuote(quote: $quote)
    }
`;

const createOpenbravoSalesOrganization = gql`
    mutation createOpenbravoSalesOrganization($salesOrganization: com_methodia_salesportal_domain_OpenbravoSalesOrganizationInput){
        createOpenbravoSalesOrganization(salesOrganization: $salesOrganization)
    }
`;

const salesOrganizationsQuery = gql`
    query salesOrganizations($pageNumber: Int, $pageSize: Int, $sort: com_methodia_salesportal_domain_SortInput, $where: String) {
        getSalesOrganizations(pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort, where: $where){
            content{
                organizationId
                businessPartnerId
                name
                active
                category
                avatarUrl
                parent {
                    name
                }
            }
            first
            last
            number
            numberOfElements
            size
            totalElements
            totalPages
            random
            pageable{
                pageNumber
                pageSize
                paged
                offset
            }
        }
    }
`;

const getQuote = gql`
    query getQuoteByTaskId($processInstanceId: String!){
        getQuoteByProcessInstanceId(processInstanceId: $processInstanceId){
            id
            extraData
            companyNumber
            products{
                name
                unitRate
                standingCharge
                commission
                commissionUnitRate
                commissionStandingCharge
                meterPoint
                annualQuantity
                annualQuantityIndustry
                greenGas
                amr
                greenEnergy
            }
            date{
                time
            }
            status{
                key
                name
            }
            statusReason
            clientName
            tariffName{
                aqHigh
                aqLow
                billingFrequency
                brokerId
                earliestStartDate
                id
                latestStartDate
                ldz
                name
                sc
                tariffCode
                unitRate
            }
            
            supplyAddress{
                block
                city
                county
                dependentCity
                dependentStreet
                doubleDependentCity
                houseNumber
                houseNumberAdd
                mpan
                mprn
                postalCode
                street
                gas_meter_serial_number
                exz
            }
            smartMeter
            ownerOrganizationId
            subOwnerOrganizationId
            ownerUserId
            processInstanceId
            startDate
            endDate
            saleType
            contractDuration
        }
    }
`

const getQuoteById = gql`
    query getQuote($id: Int){
        getQuote(id: $id){
            id
            extraData
            companyNumber
            products{
                name
                unitRate
                standingCharge
                commission
                commissionUnitRate
                commissionStandingCharge
                meterPoint
                annualQuantity
                annualQuantityIndustry
                greenGas
                amr
                greenEnergy
            }
            clientName
            tariffName{
                aqHigh
                aqLow
                billingFrequency
                brokerId
                earliestStartDate
                id
                latestStartDate
                ldz
                name
                sc
                tariffCode
                unitRate
            }
            
            supplyAddress{
                block
                city
                county
                dependentCity
                dependentStreet
                doubleDependentCity
                houseNumber
                houseNumberAdd
                ldz
                exz
                mpan
                mprn
                postalCode
                street
                gas_meter_serial_number
                meterData{
                    mprn
                    gas_meter_serial_number
                }
            }
            smartMeter
            ownerOrganizationId
            subOwnerOrganizationId
            ownerUserId
            startDate
            endDate
            saleType
        }
    }
`

const createUpdateAgreement = gql`
    mutation createUpdateAgreement($agreement: com_methodia_salesportal_domain_AgreementInput){
        createUpdateAgreement(agreement: $agreement)
    }
`;

const setQuoteStatusMutation = gql`
    mutation setQuoteStatusMutation($quoteId: Int, $quoteStatus: com_methodia_salesportal_domain_QuoteStatusInput, $statusReason: String){
        setQuoteStatus(quoteId: $quoteId, quoteStatus: $quoteStatus, statusReason: $statusReason)
    }
`;

const getQuotesQuery = gql`
    query getQuotesQuery($organizationId: String, $username: String, $pageNumber: Int, $pageSize: Int, $sort: com_methodia_salesportal_domain_SortInput, $where: String){
        getQuotes(organizationId: $organizationId, username: $username, pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort, where: $where){
            content{
                id
                clientName
                supplyAddress{
                    postalCode
                }
                date{
                    time
                }
                tariff
                tariffName{
                    aqHigh
                    aqLow
                    billingFrequency
                    brokerId
                    earliestStartDate
                    id
                    latestStartDate
                    ldz
                    name
                    sc
                    tariffCode
                    unitRate
                }

                products{
                    name
                    meterPoint
                    commissionUnitRate
                    commissionStandingCharge
                }
                status{
                    key
                    name
                }
                statusReason
                processInstanceId
                ownerUserId
                ownerOrganizationName
                subOwnerOrganizationName
                saleType
                startDate
                endDate
            }
            first
            last
            number
            numberOfElements
            size
            totalElements
            totalPages
            random
            pageable{
                pageNumber
                pageSize
                paged
                offset
            }
        }
    }
`;

const getCommissionReportsQuery = gql`
    query getCommissionReportsQuery($organizationId: String!){
        getCommissionReports(organizationId: $organizationId){
            id
            startDate{
                time
            }
            endDate{
                time
            }
            created{
                time
            }
            createdBy
            document
            ownerOrganizationId
        }
    }
`;
const createCommissionReportMutation = gql`
    mutation createCommissionReportMutation($startDate: Date!, $endDate:Date!, $brokerId:String!){
        createCommissionReport(startDate: $startDate, endDate:$endDate, brokerId:$brokerId)
    }
`;
const createSalesUserMutation = gql`
    mutation CreateSalesUserMutation($user: com_methodia_salesportal_domain_CockpitUserInput){
        createSalesUser(user: $user)
    }
`;

const updateSalesUserMutation = gql`
    mutation UpdateSalesUserMutation($user: com_methodia_salesportal_domain_CockpitUserInput){
        updateSalesUser(user: $user)
    }
`;

const disableOpenbravoSalesOrganization = gql`
    mutation DisableOpenbravoSalesOrganization($id: String!){
        disableOpenbravoSalesOrganization(id: $id){
            isActive
        }
    }
`;
const getQuoteStatusFlowFromQuery = gql`
query GetQuoteStatusFlowFrom($from: com_methodia_salesportal_domain_QuoteStatusInput!){
    getQuoteStatusFlowFrom(from: $from){
        to {
            key
            name
            requireReason
        }
        
    }
}
`;
const getAgreementStatusFlowFromQuery = gql`
    query GetAgreementStatusFlowFrom($from: com_methodia_salesportal_domain_AgreementStatusInput!){
        getAgreementStatusFlowFrom(from: $from){
            to {
                key
                name
                action
                requireReason
            }

        }
    }
`;
const agreementsQuery = gql`
    query getAgreementsQuery($organizationId: String, $username: String, $pageNumber: Int, $pageSize: Int, $sort: com_methodia_salesportal_domain_SortInput, $where: String) {
        getAgreements(organizationId: $organizationId, username: $username, pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort, where: $where){
            content{
                    id
                obId
                processInstanceId
                date {
                    time
                }
                documentNumber
                clientName
                salesOrgName
                tariff
                tariffName{
                    name
                }
                products{
                    name
                    meterPoint
                }
                extraData
                status{
                    key
                    name
                    action
                    requireReason
                }
                startDate
                endDate
                ownerOrganizationId
                subOwnerOrganizationId
                ownerOrganizationName
                subOwnerOrganizationName
            }
            first
            last
            number
            numberOfElements
            size
            totalElements
            totalPages
            random
            pageable{
                pageNumber
                pageSize
                paged
                offset
            }
        }
    }   
`;

const agreementQuery = gql`
    query getAgreementQuery($id: Int) {
        getAgreement(id: $id){
            aq
            billingAddress{
                block
                city
                county
                dependentCity
                dependentStreet
                doubleDependentCity
                houseNumber
                houseNumberAdd
                ldz
                mpan
                mprn
                postalCode
                street
                gas_meter_serial_number
            }
            billingContact{
                accountPassword
                email
                firstName
                lastName
                phone
                sameAsPrimary
                billingPreferencePost
                billingPreferenceEmail
            }
            businessType
            companySize
            clientName
            commission 
            companyNumber 
            contractDuration
            date{
                time
            }
            documentNumber 
            endDate 
            extraData 
            id 
            ldz 
            mpan
            mprn
            obId 
            ownerOrganizationId
            subOwnerOrganizationId
            ownerOrganizationName 
            ownerUserId 
            primaryAddress{
                block
                city
                county
                dependentCity
                dependentStreet
                doubleDependentCity
                houseNumber
                houseNumberAdd
                ldz
                mpan
                mprn
                postalCode
                street
                gas_meter_serial_number
            }
            primaryContact {
                accountPassword 
                email 
                firstName 
                lastName 
                phone 
            }
            previousAddress{
                block
                city
                county
                dependentCity
                dependentStreet
                doubleDependentCity
                houseNumber
                houseNumberAdd
                ldz
                mpan
                mprn
                postalCode
                street
                gas_meter_serial_number
            }
            processInstanceId 
            products {
                amr 
                annualQuantity
                annualQuantityIndustry 
                commission 
                commissionStandingCharge 
                commissionUnitRate 
                greenEnergy 
                greenGas 
                meterPoint 
                name 
                standingCharge 
                unitRate 
            }
            salesOrgName 
            secondaryAddress{
                block
                city
                county
                dependentCity
                dependentStreet
                doubleDependentCity
                houseNumber
                houseNumberAdd
                ldz
                mpan
                mprn
                postalCode
                street
                gas_meter_serial_number
            }
            creditCheckAddress{
                block
                city
                county
                dependentCity
                dependentStreet
                doubleDependentCity
                houseNumber
                houseNumberAdd
                ldz
                mpan
                mprn
                postalCode
                street
                gas_meter_serial_number
            }
            secondaryContact{
                accountPassword
                email
                firstName
                lastName
                phone
            }
            creditCheckContact{
                accountPassword
                email
                firstName
                lastName
                phone
            }
            sourceQuoteId 
            startDate 
            status{
                key
                name
            }
            supplyAddress {
                block
                city
                county
                dependentCity
                dependentStreet
                doubleDependentCity
                houseNumber
                houseNumberAdd
                ldz
                exz
                mpan
                mprn
                postalCode
                street
                gas_meter_serial_number
            }
            sortCode
            bankAccountName
            accountNumber
            paymentMethod
            tariffName {
                aqHigh 
                aqLow 
                bespokePrice 
                billingFrequency 
                brokerId 
                earliestStartDate
                gas_meter_serial_number 
                id 
                latestStartDate 
                ldz 
                name 
                sc 
                tariffCode 
                unitRate 
            }
            saleType
            SMS
            email
            mail
            telephone
            loaStartDate
            loaEndDate
            changeOfTenancy
            changeOfTenancyDate
            climateChangeAgreement
        }
    }
`;
const setAgreementStatusMutation = gql`
    mutation setAgreementStatusMutation($agreementId: Int, $agreementStatus: com_methodia_salesportal_domain_AgreementStatusInput){
        setAgreementStatus(agreementId: $agreementId, agreementStatus: $agreementStatus)
    }
`;

const getQuoteStatusesQuery = gql`
    query{
        getQuoteStatuses{
            key
            name
        }
    }
`;

const getAgreementStatusesQuery = gql`
    query{
        getAgreementStatuses{
            key
            name
        }
    }
`;
const getQuoteProductNamesQuery = gql`
    query{
        getQuoteProductNames
    }
`;

const getOrganizationParentsQuery = gql`
    query {
        getOrganizationParents{
            name
            organizationId
        }
    }
`;

const saveUiTransportMutation = gql`
    mutation saveUiTransportMutation($uiTransportWrapper:com_methodia_cockpit_core_domain_ui_UiTransportWrapperInput){
        saveUiTransport(uiTransportWrapper: $uiTransportWrapper)
    }
`;

const contractsListPagedQuery = gql`
    query contractsListPaged($pageNumber: Int, $pageSize: Int, $sort: com_methodia_cockpit_core_domain_SortInput, $where: String){
        contractsListPaged(pageNumber:$pageNumber, pageSize: $pageSize, sort:$sort, where: $where){
            content{
                id
                documentnumber
                businessPartner{
                    id
                }
                estate{
                    mDMEstate {
                        id
                        estateNumber
                        locationAddress {
                            mdmZip
                        }
                        mDMMeterPointList{
                            name
                            mDMMeterRegisterList{
                                meter{
                                    serialNumber
                                }
                            }
                        }
                    }
                }
            }
            count
            first
            last
            number
            numberOfElements
            totalElements
            totalPages
            pageable{
                pageNumber
                pageSize
                paged
                offset
            }
        }
    }
`;

const getBpmServiceInstancesQuery = gql`
    query getBpmServiceInstances{
        getBpmServiceInstances
    }
`;

const siteQuery = gql`
    query Sites($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String) {
        Site(page: {pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort}, spec: {where: $where}) {
            content {
                id
                name
                address
                meterPoints {
                    id
                    identifier
                    site {
                        name
                    }
                    registers {
                        id
                        digits
                        rate
                        consumptions {
                            presentReading {
                                date
                                type
                                value
                            }
                        }
                        meter {
                            id
                            serialNumber
                            type
                        }
                    }
                }
                properties {
                    status
                    account {
                        number
                        customer {
                            id
                            name
                        }
                    }
                }
            }
            size
            totalPages
            first
            last
            number
            numberOfElements
            size
            totalElements
            totalPages
            pageable {
                pageNumber
                pageSize
                paged
                offset
            }
        }
    }

`;


const siteReadingsQuery = gql`
    query ReadingBySite($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String, $siteId: String) {
        ReadingBySite(page: {pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort}, spec: {where: $where}, siteId: $siteId) {
            content {
                id
                date
                value
                type
                uploadMethod
                register {
                    id
                    meterRegisterId
                    meter {
                        id
                        serialNumber
                    }
                    meterPoint {
                        id
                        identifier
                    }
                }
            }
            size
            totalPages
            first
            last
            number
            numberOfElements
            size
            totalElements
            totalPages
            pageable {
                pageNumber
                pageSize
                paged
                offset
            }
        }
    
    
        Reading(page: {pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort}, spec: {where: $where}) {
            content {
                id
                date
                value
                type
                uploadMethod
                register {
                    id
                    meter {
                        id
                        serialNumber
                    }
                    meterPoint {
                        id
                        identifier
                    }
                }
            }
            size
            totalPages
            first
            last
            number
            numberOfElements
            size
            totalElements
            totalPages
            pageable {
                pageNumber
                pageSize
                paged
                offset
            }
        }
    }
`;

const scheduleReport = gql`
    mutation createReportSchedule($reportSchedule: ReportScheduleInput) {
        createReportSchedule(reportSchedule: $reportSchedule) {
            reportName: reportName
            periodCount: periodCount
            periodType: periodType
            schedule: schedule
            parameters: parameters
            status: status
        }
    }
`;

const scheduleExecution = gql`
    query ScheduleExecution($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String) {
        ScheduleExecution(page: {pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort}, where: $where) {
            content {
                id
                created
                reportName
                status
                parameters
                schedule
                periodCount
                periodType
                skipNextExecutionDate
            }
            pageable {
                pageNumber
                pageSize
                paged
            }
            size
            totalPages
            number
            numberOfElements
            totalElements
        }
    }
`;

const sitesByProperty = gql`
    query SitesByProperty($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String) {
        SitesByProperty(page: {pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort}, where: $where) {
            content {
                id
                name
                address
                startDate
                endDate
                site {
                    id
                    name
                    address
                    meterPoints {
                        id
                        identifier
                        purpose
                        status
                        supplyDate
                        supplyEndDate
                        hasAmr
                        amrScheduleCode
                        energisationStatus
                        hasCorrector
                        name
                        site {
                            name
                        }
                        registers {
                            id
                            meterRegisterId
                            settlementType
                            digits
                            rate
                            effectiveTo
                            readings {
                                date
                                type
                                value
                                uploadMethod
                            }
                            meter {
                                id
                                serialNumber
                                type
                            }
                        }
                    }
                }
                properties {
                    startDate
                    endDate
                    site {
                        meterPoints {
                            id
                            identifier
                            registers {
                                id
                                digits
                                rate
                                readings {
                                    date
                                    type
                                    value
                                    uploadMethod
                                }
                                meter {
                                    id
                                    serialNumber
                                    type
                                }
                            }
                        }
                    }
                }
                status
                account {
                    number
                    status
                    organization {
                        id
                    }
                }
            }
            size
            totalPages
            first
            last
            number
            numberOfElements
            size
            totalElements
            totalPages
            pageable {
                pageNumber
                pageSize
                paged
                offset
            }
        }
    }

`;

const customers = gql`query Customer($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String) {
    Customer(page: {pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort}, spec: {where: $where}) {
        content {
            id
            name
            number
            referenceNumber
            address
            status
            created
            createdBy
            updated
            updatedBy
            accounts {
                id
                number
                properties {
                    id
                    site {
                        id
                        address
                        name
                        meterPoints {
                            id
                            identifier
                        }
                    }
                }
            }
            accounts {
                number
            }
        }
        size
        totalPages
        first
        last
        number
        numberOfElements
        size
        totalElements
        totalPages
        pageable {
            pageNumber
            pageSize
            paged
            offset
        }
    }
}
`;
const customerId = gql`query CustomerId($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String) {
    Customer(page: {pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort}, spec: {where: $where}) {
        content {
            id
        }
        size
        totalPages
        first
        last
        number
        numberOfElements
        size
        totalElements
        totalPages
        pageable {
            pageNumber
            pageSize
            paged
            offset
        }
    }
}
`;
const meterPointNames = gql `query MeterPointNames($where:String, $field:String) {
    MeterPoint (page: {pageSize: 100, pageNumber: 0, sort:{orders: [{property: $field, direction: "asc"}]}}, spec:{where: $where}) {
        content {
            identifier
            name
        }
    }
}`;

const mpanNames = gql `query MpanNames($where:String, $field:String) {
    Mpan (page: {pageSize: 100, pageNumber: 0, sort:{orders: [{property: $field, direction: "asc"}]}}, spec:{where: $where}) {
        content { 
            meterPoint {
                identifier
                name
            }
        }
    }
}`;

const customerNames = gql `query CustomersList($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String) {
    Customer(page: {pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort}, spec: {where: $where}) {
        content {
            id
            number
            name
            referenceNumber
            status
            address
            accounts{
                number
            }
        }
    }
}
`;

const siteNames = gql `query SitesList($field:String, $where: String) {
    Site(page: {pageNumber: 0, pageSize: 100, sort:{orders: [{property: $field, direction: "asc"}]}}, spec: {where: $where}) {
        content {
            id
            name
        }
    }
}`;


const accountNumbers = gql `query Account($field:String, $where: String) {
    Account(page: {pageNumber: 0, pageSize: 100, sort:{orders: [{property: $field, direction: "asc"}]}}, spec: {where: $where}) {
        content {
            id
            number
            customer{
                id
                name
            }
        }
    }
}
`;

const PropertiesByAccauntStatus = gql `query PropertiesByAccauntStatus($pageNumber: Int, $pageSize: Int, $sort: SortInput, $where: String) {
    PropertyByAccountStatus(page: {pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort}, where: $where) {
        content {
            id
            name
            address
            startDate
            endDate
            parentProperty {
                id
            }
            site {
                id
                name
                address
                meterPoints {
                    id
                    identifier
                    purpose
                    supplyDate
                    hasAmr
                    amrScheduleCode
                    hasCorrector
                    name
                    site {
                        name
                    }
                    registers {
                        id
                        meterRegisterId
                        digits
                        rate
                        readings {
                            date
                            type
                            value
                            uploadMethod
                        }
                        consumptions {
                            presentReading {
                                date
                                type
                                value
                            }
                        }
                        meter {
                            id
                            serialNumber
                            type
                        }
                    }
                }
            }
            properties {
                startDate
                endDate
                site {
                    meterPoints {
                        id
                        identifier
                        registers {
                            id
                            digits
                            rate
                            readings {
                                date
                                type
                                value
                                uploadMethod
                            }
                            consumptions {
                                presentReading {
                                    date
                                    type
                                    value
                                }
                            }
                            meter {
                                id
                                serialNumber
                                type
                            }
                        }
                    }
                }
            }
            status
            account {
                number
                status
                customer {
                    contracts {
                        product
                    }
                }
            }
        }
        size
        totalPages
        first
        last
        number
        numberOfElements
        size
        totalElements
        totalPages
        pageable {
            pageNumber
            pageSize
            paged
            offset
        }
    }
}
`;

export {getBusinessPartnerShortQuery, getBusinessPartnerQuery, getInvoiceQuery, getBalance, getContractQuery,
    getUserQuery, getUsers, getAllRoles,getAllPermissions, getRolesForUserQuery, createUserMutation, createUserMutation2, updateUserMutation, deleteUserMutation,
    createRolePermissionsMutation, deleteRolePermissionsMutation, createUpdateRolesMutation,
    changePasswordMutation, changeOwnPasswordMutation, createUserRoles, deleteUserRoles,
    createNoteMutation, updateNoteMutation, deleteNoteMutation,
    uiConfigurationQuery, allUiConfigurationsQuery, allUiMenuItemsQuery, organizationsQuery, systemsQuery, createReadingMutation, createReadingsMutation,
    getTasksQuery, getTaskVariableQuery, createTaskMutation, updateTaskMutation, deleteTaskMutation,
    createTaskCommentMutation, getTaskCommentsQuery,
    startProcessMutation, periodsQuery, getContractId, getAddresses,
    createUpdateQuote, createUpdateAgreement, createOpenbravoSalesOrganization, getQuote, setQuoteStatusMutation, getQuotesQuery,
    createSalesUserMutation, updateSalesUserMutation,
    disableOpenbravoSalesOrganization, getCommissionReportsQuery, createCommissionReportMutation,
    getQuoteStatusFlowFromQuery,getAgreementStatusFlowFromQuery, getQuoteById, agreementsQuery, agreementQuery, setAgreementStatusMutation,
    getLdz, salesOrganizationsQuery, getQuoteStatusesQuery, getAgreementStatusesQuery, getQuoteProductNamesQuery,
    updateSystemMutation, unlockUserMutation, getOrganizationParentsQuery, saveUiTransportMutation, contractsListPagedQuery, getBpmServiceInstancesQuery, siteQuery,
    siteReadingsQuery, scheduleReport, scheduleExecution, sitesByProperty, customers, customerId, meterPointNames, customerNames, siteNames, accountNumbers, PropertiesByAccauntStatus, mpanNames};
