import React, {Component} from 'react';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {showPaymentInfo: false};
    }

  render() {
      const d = new Date();
    return (
      <footer role="contentinfo">
          <div className="footer-top">
              <div className="inner clearfix">
                  <div className="col" style={{display: 'none'}}><p><a href="/#/" title="Home">Home</a></p></div>
                  <div className="col"><p className="footer-header">Important Info</p>
                      <ul className="submenu">
                          <li><a target="_blank" href="https://business.totalenergies.uk/information-centre/emergency-essentials" title="Emergency Essentials">Emergency Essentials</a></li>
                          <li><a target="_blank" href="https://connect.totalgp.com/mygatewaysupport" title="MyGateway Support">MyGateway Support</a></li>
                          <li style={{display: 'none'}}><a target="_blank" href="#" title="Contact Us">Contact Us</a>
                          </li>
                          <li><a title="Payment information" onClick={() => this.setState({ showPaymentInfo: !this.state.showPaymentInfo})}>Payment information</a></li>
                          <li><a target="_blank" href="https://business.totalenergies.uk/information-centre/energy-essentials/smart-and-amr-meters-business/half-hourly-energy-consumption" title="Consumer Data Provision">Consumer Data Provision</a></li>
                      </ul>
                      <p style={{fontWeight: 'normal', textTransform: 'none', fontSize: '13px', display: (this.state.showPaymentInfo ? 'block' : 'none')}}>
                        
                          <br/>
                          Bank Name: HSBC Bank Plc<br/>
                          Account Name: TGP EBE Collections<br/>
                          Account No: 81403346<br/>
                          Sort Code: 40 - 02 - 50<br/>
                          Branch: London Park Street SE1<br/>
                      </p>
                  </div>
                  <div className="col"><p className="footer-header">Forms</p>
                      <ul className="submenu">
                          <li><a target="_blank" href="https://dxm.content-center.totalenergies.com/api/wedia/dam/variation/xysh7dg731tah5ehpwocmhkmch/original,default?__hstc=258973845.71babbc735907bce6a7587e0262d804a.1724744851210.1732782237166.1732784347529.183&__hssc=258973845.12.1732784347529&__hsfp=2524696164" title="VAT Form">VAT Form</a></li>
                          <li><a target="_blank" href=" https://dxm.content-center.totalenergies.com/api/wedia/dam/variation/xysh7dg731tahyitwq6whrmo3o/original,default?__hstc=258973845.71babbc735907bce6a7587e0262d804a.1724744851210.1732782237166.1732784347529.183&__hssc=258973845.12.1732784347529&__hsfp=2524696164" title="DD Form">DD Form</a></li>
                          <li><a target="_blank" href="https://www.gov.uk/government/publications/climate-change-levy-supplier-certificate-pp11" title="CCL Supplier Certificate (PP11)">CCL Supplier Certificate (PP11)</a></li>
                      </ul>
                      <br />
                  </div>
                      <div className="col"><p className="footer-header">Siteworks (Gas)</p>
                      <ul className="submenu">
                          <li><a target="_blank" href="https://evp-api-totalenergies-dam-prod-cdn.wedia-group.com/api/media/play.html?PlayerUid=y60338b14d0a1d&MediaUid=3cc8ad4c-f6da-486d-9f43-456f8de0a994" title="Removals + Disconnections">Removals + Disconnections</a></li>
                          <li><a target="_blank" href="https://evp-api-totalenergies-dam-prod-cdn.wedia-group.com/api/media/play.html?PlayerUid=y60338b14d0a1d&MediaUid=f9327d4a-be9f-4317-93dc-5e7db5cf9820" title="Other Requests Checks">Other Requests Checks</a></li>
                          <li><a target="_blank" href="https://evp-api-totalenergies-dam-prod-cdn.wedia-group.com/api/media/play.html?PlayerUid=y60338b14d0a1d&MediaUid=6147fbdc-fc2c-4c9d-9a45-d1351f49019c" title="Installs Upgrades Relocations">Installs Upgrades Relocations</a></li>
                      </ul>
                  </div>
                  <div className="col"><p className="footer-header">Siteworks (Electricity)</p>
                      <ul className="submenu">
                          <li><a target="_blank" rel="noopener noreferrer" href="https://evp-api-totalenergies-dam-prod-cdn.wedia-group.com/api/media/play.html?PlayerUid=y60338b14d0a1d&MediaUid=21901a0f-0614-4367-a613-b67cf4b86ab1">Siteworks</a></li>
                          <li><a target="_blank" rel="noopener noreferrer" href="https://evp-api-totalenergies-dam-prod-cdn.wedia-group.com/api/media/play.html?PlayerUid=y60338b14d0a1d&MediaUid=8a2b1419-9955-4eeb-9844-6ddca36c95da">Electricity Siteworks FAQs</a></li>
                      </ul>
                  </div>
              </div>
          </div>
          <div className="footer-bottom">
              <div className="inner">
                  <ul className="links">

                      <li><a href="https://www.gas-power.total.co.uk/sitemap.xml" title="Sitemap" target="_blank">Sitemap</a></li>
                      <li><a href="https://www.gas-power.total.co.uk/cookie-policy" title="Cookie Policy" target="_blank">Cookie Policy</a></li>
                      <li><a href="https://www.gas-power.total.co.uk/legal" title="Legal" target="_blank">Legal</a></li>
                      <li><a href="https://www.gas-power.total.co.uk/privacy-policy" title="Privacy Policy" target="_blank">Privacy Policy</a></li>
                      <li><a href="https://www.gas-power.total.co.uk/customer-data" title="Customer Data" target="_blank">Customer Data</a></li>
                      <li>© TotalEnergies Gas & Power Limited. All rights reserved</li>
                  </ul>
              </div>
          </div>
          <div id="block-block-10" className="block block-block">
              <div>
                  <script type="text/javascript"
                          src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5c62d6f9bb887c42"></script>
              </div>
          </div>
          <div aria-label="Scroll to top" id="nav_up" className="nav_up" style={{display: "block"}}></div>
      </footer>
    );
  }
}

export default Footer;
